import React from "react"
import { Col, Row, Button } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TreeDivider } from "../../components/blocks/TreeDivider"
import TreeCarBackground from "../../images/overcrest/two-car-tree.png"
import { faGooglePlay, faAppStoreIos } from "@fortawesome/free-brands-svg-icons"

const DownloadButton = ({ link, children, ...rest }) => {
  return (
    <Button
      className="download-button py-1"
      variant="outline-primary"
      href={link}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default function RallyDownload({ params, location }) {
  const approvalLinkId = React.useMemo(() => {
    const queryParams = new URLSearchParams(location.search)
    return queryParams.get("approval_link")
  }, [location])

  return (
    <div className="vh-100">
      <SEO
        title="Overcrest Rally 2022 App Download"
        desc="Overcrest Rally 2022 Rallista App Download Page"
        image="../../images/overcrest/oc-rally-22-wordmark-color.png"
      />
      <Helmet>
        <body className="bg-tan overcrest" />
      </Helmet>
      <Row className="h-100">
        <TreeDivider side="left" />
        <Col
          xs={12}
          lg={4}
          className="center-column"
          style={{
            backgroundImage: `url(${TreeCarBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundSize: "contain",
          }}
        >
          <div
            className="w-100 h-100"
            style={{ backgroundColor: `rgba(245, 240, 220, 0.85)` }}
          >
            {" "}
            <Row className="d-flex justify-content-center py-4 mt-5" noGutters>
              <StaticImage
                src="../../images/overcrest/oc-rally-22-wordmark-color.png"
                alt="Overcrest Rally 2022 Logo"
                placeholder="blurred"
                layout="constrained"
                aspectRatio={16 / 9}
                objectFit="contain"
                height={200}
                transformOptions={{ fit: "fill" }}
              />
            </Row>
            <Row noGutters className="d-flex justify-content-center mt-3">
              <h1 className="text-center">You&lsquo;re all set!</h1>
            </Row>
            <Row
              noGutters
              className="d-flex justify-content-center align-items-center my-3"
            >
              <h4 className="text-center">
                Download the Rallista app to be prepared on Sept 8th
              </h4>
            </Row>
            <Row
              noGutters
              className="d-flex justify-content-around align-items-center mb-3 py-2"
              style={{ height: 250 }}
            >
              <Col md={4} className="d-flex justify-content-center my-2">
                <StaticImage
                  src="../../images/favicon.png"
                  alt="Rallista App Logo"
                  placeholder="blurred"
                  layout="constrained"
                  objectFit="contain"
                  transformOptions={{ fit: "fill" }}
                  className="rounded"
                />
              </Col>
              <Col
                md={4}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <Row>
                  <DownloadButton
                    id="ios-download"
                    link="https://apps.apple.com/us/app/rallista/id1380338355"
                    onClick={() => {
                      if (!approvalLinkId) {
                        return
                      }
                      fetch(`/approvals/${approvalLinkId}?download=ios`).catch(
                        (err) => console.error(err)
                      )
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faAppStoreIos}
                      size="lg"
                      style={{ verticalAlign: "-0.15rem" }}
                    />
                    <span className="ml-2">Download iOS</span>
                  </DownloadButton>
                </Row>
                <Row className="mt-4">
                  <DownloadButton
                    id="google-play-download"
                    link="https://play.google.com/store/apps/details?id=com.rallista.rallista"
                    onClick={() => {
                      if (!approvalLinkId) {
                        return
                      }
                      fetch(
                        `/approvals/${approvalLinkId}?download=android`
                      ).catch((err) => console.error(err))
                    }}
                  >
                    <FontAwesomeIcon icon={faGooglePlay} size="sm" />
                    <span className="ml-2">Download Android</span>
                  </DownloadButton>
                </Row>
              </Col>
            </Row>
            <Row
              noGutters
              className="d-flex flex-column align-items-center my-5"
            >
              <h5 className="text-center" style={{ maxWidth: "15rem" }}>
                See you on the road September 8th - 10th
              </h5>
            </Row>
          </div>
        </Col>
        <TreeDivider side="right" />
      </Row>
    </div>
  )
}
