import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

const Facebook = ({ url, name, type, title, desc, image, locale, fbAppId }) => (
  <Helmet>
    <meta property="og:url" content={url} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={desc} />
    {image ? <meta property="og:image" content={image} /> : null}
    {image ? <meta property="og:image:alt" content={desc} /> : null}
    {name && <meta property="og:site_name" content={name} />}
    {locale ? <meta property="og:locale" content={locale} /> : null}
    {type ? <meta property="og:type" content={type} /> : null}
    {fbAppId ? <meta property="fb:app_id" content={fbAppId} /> : null}
  </Helmet>
)

export default Facebook

Facebook.propTypes = {
  url: PropTypes.string.isRequired,
  locale: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  image: PropTypes.string,
  name: PropTypes.string,
  fbAppId: PropTypes.string,
}

Facebook.defaultProps = {
  type: "website",
  name: null,
}
