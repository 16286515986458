import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Facebook from "./Facebook"
import Twitter from "./Twitter"

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({ title, desc, image, pathname, node }) => {
  const { site } = useStaticQuery(query)

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultImage,
      headline,
      siteLanguage,
      ogLanguage,
      author,
      organization,
      twitter,
      facebook,
      fbAppId,
    },
  } = site

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: image || defaultImage,
    url: `${siteUrl}${pathname || ""}`,
  }

  const year = new Date().getFullYear()

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: siteUrl,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      "@type": "Brand",
      name: author,
    },
    copyrightHolder: {
      "@type": "Organization",
      name: organization,
    },
    copyrightYear: `${year}`,
    creator: {
      "@type": "Brand",
      name: author,
    },
    publisher: {
      "@type": "Brand",
      name: author,
    },
    // datePublished: '2019-01-18T10:30:00+01:00',
    dateModified: buildTime,
    // image: {
    //   '@type': 'ImageObject',
    //   url: `${siteUrl}${defaultBanner}`,
    // },
  }

  // Initial breadcrumb list

  // const itemListElement = [
  //   {
  //     "@type": "ListItem",
  //     item: {
  //       "@id": siteUrl,
  //       name: "Homepage",
  //     },
  //     position: 1,
  //   },
  // ]

  // const breadcrumb = {
  //   '@context': 'http://schema.org',
  //   '@type': 'BreadcrumbList',
  //   description: 'Breadcrumbs list',
  //   name: 'Breadcrumbs',
  //   itemListElement,
  // }
  // <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type="website"
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
        fbAppId={fbAppId}
      />
      <Twitter
        title={seo.title}
        image={seo.image}
        desc={seo.description}
        username={twitter}
      />
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  node: PropTypes.object,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  image: null,
  pathname: null,
  node: null,
  lang: null,
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultImage: image
        headline
        siteLanguage
        ogLanguage
        author
        organization
        twitter
        facebook
        fbAppId
      }
    }
  }
`
